import styled from "styled-components";
import {muiColor, muiSpacing, muiTypography, StylePropsType} from "@gannochenko/ui.styled-components";
import {backgroundCover} from "@gannochenko/etc";

export const Root = styled.div`
    padding: ${muiSpacing(1)};
    margin: 0 ${muiSpacing(2)} ${muiSpacing(2)} 0;
    border: 2px solid ${muiColor('primary.main')};
    ${muiTypography('label')};
    border-radius: 999999px;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
`;

export const RootAsLink = styled.a`
    padding: ${muiSpacing(1)};
    margin: 0 ${muiSpacing(2)} ${muiSpacing(2)} 0;
    border: 2px solid ${muiColor('primary.main')};
    &:hover {
      border-color: ${muiColor('primary.lighter')};
    }
    ${muiTypography('label')};
    border-radius: 999999px;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    text-decoration: none !important;
    color: inherit;
    transition: all 200ms ease;
`;

export const Label = styled.div`
    padding: 0 ${muiSpacing(3)} 0 ${muiSpacing(3)};
`;

export const Logo = styled.div`
    border-radius: 999999px;
    border: 1px solid ${muiColor('grey.400')};
    padding: ${muiSpacing(2)};
    overflow: hidden;
    min-width: 2.4rem;
    display: flex;
    justify-content: center;
`;

export type SkillsV2SkillLogoPropsType = Omit<StylePropsType, "width"> & {
    image: string;
    width?: string;
};

export const Image = styled.div<SkillsV2SkillLogoPropsType>`
    ${({ image }) => backgroundCover(image)};
    width: ${({ width }) => (width !== undefined ? width : muiSpacing(5))};
    height: ${muiSpacing(5)};
`;
