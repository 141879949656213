import React from 'react';

import { Root, GridCell } from './style';
import { HobbiesListPropsType } from './type';
import Grid from "@material-ui/core/Grid";
import {ProjectsEntry} from "../../type";
import {Card} from "../Card";
import {fillTemplate, PROJECTS_DETAIL} from "../../pathTemplates";

export const ProjectsList = ({ data }: HobbiesListPropsType) => {
    if (!data) {
        console.log("ProjectsList: no data");
        return null;
    }

    return (
        <Root>
            <Grid container spacing={5}>
                {data.map((itemData) => {
                    const entry = itemData.node.frontmatter as ProjectsEntry;
                    const { images, title, date, description, slug } = entry;
                    const path = fillTemplate(PROJECTS_DETAIL, { slug: slug ?? "" });

                    return (
                        <GridCell md={4} sm={6} xs={12} key={itemData.node.id} component="div">
                            <Card
                                image={images[0].image ?? null}
                                title={title}
                                link={path}
                                date={date}
                                showOnlyYear
                                text={description ?? ""}
                                imageYPosition={100}
                            />
                        </GridCell>
                    );
                })}
            </Grid>
        </Root>
    );
};
