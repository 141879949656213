import React from "react";
import {Root, Label, Logo, Image, RootAsLink} from "./style";

type SkillV2 = Partial<{
    title: string;
    logo: any;
    width: string;
    link: string;
}>;

export function SkillV2({title, logo, width, link}: SkillV2) {
    const RootNode = !!link ? RootAsLink : Root;

    return (
        <RootNode href={link} taget="_blank">
            {
                !!logo
                &&
                <Logo>
                    <Image
                        image={logo}
                        width={width}
                    />
                </Logo>
            }
            <Label>{title}</Label>
        </RootNode>
    );
}
