import React, {FC} from 'react';

import {SkillsV2PropsType} from './type';
import {SkillsV2Root} from './style';
import {useSkillsV2} from './hooks/useSkillsV2';
import {SkillV2} from "../SkillV2/SkillV2";

export const SkillsV2: FC<SkillsV2PropsType> = (props) => {
    const { rootProps, skills } = useSkillsV2(props);

    return (
        <SkillsV2Root {...rootProps}>
            {skills.map((skill) => (
                <SkillV2 key={skill.title} title={skill.title} logo={skill.logo} width={skill.widthV2} link={skill.link} />
            ))}
        </SkillsV2Root>
    );
};

SkillsV2.defaultProps = {};
