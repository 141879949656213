import React from 'react';

import { BlogPostListContainer, LinkContainer, Posts, NothingFound, GridCell } from './style';
import { BlogListPropsType } from './type';
import { Link } from '../Link';
import {SearchBar} from "../SearchBar";
import {Container} from "../Container";
import {useBlogList} from "./hooks/useBlogList";
import Grid from "@material-ui/core/Grid";
import {Card} from "../Card";
import {RoundButton} from "../RoundButton";

export function BlogList(props: BlogListPropsType) {
    const {data, searchBarProps, showLink, drafts, nothingFound, somethingFound, showSearchBar, getSeeAllProps, getItemProps, seeAllButtonText} = useBlogList(props);

    return (
        <BlogPostListContainer>
            {
                showSearchBar
                &&
                <SearchBar {...searchBarProps} />
            }
            <Container>
                {
                    nothingFound
                    &&
                    <NothingFound>Nothing was found...</NothingFound>
                }
                {
                    somethingFound
                    &&
                    <Posts>
                        <Grid container spacing={5}>
                            {data.map((itemData) => {
                                return (
                                    <GridCell md={4} sm={6} xs={12} key={itemData.node.id} component="div">
                                        <Card {...getItemProps(itemData.node)} />
                                    </GridCell>
                                );
                            })}
                        </Grid>
                    </Posts>
                }
                {!!showLink && (
                    <LinkContainer>
                        <Link {...getSeeAllProps()}>
                            <RoundButton variant="outlined" color="primary">
                                {seeAllButtonText}
                            </RoundButton>
                        </Link>
                    </LinkContainer>
                )}
            </Container>
        </BlogPostListContainer>
    );
}
