import React from 'react';
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import {muiColor} from "@gannochenko/ui.styled-components";
import {PropsWithChildren} from "react";

export const RoundMUIButton = styled(Button)`
  border-radius: 99999px;
  background-color: ${muiColor('background.default')};
`;

export function RoundButton({children}: PropsWithChildren<{}>){
    return <RoundMUIButton variant="outlined" color="primary" children={children} />
}
