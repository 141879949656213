import styled from 'styled-components';
import {marginProps, muiColor, muiSpacing, muiTypography, reset,} from '@gannochenko/ui.styled-components';
import {propsBlocker} from '../../util';

import {SkillsV2RootPropsType, SkillsV2SkillLogoPropsType} from './type';
import {backgroundCover} from '@gannochenko/etc';

export const SkillsV2Root = styled.div.withConfig(
    propsBlocker,
)<SkillsV2RootPropsType>`
    ${reset};
    ${marginProps};
    display: flex;
    flex-wrap: wrap;
`;

