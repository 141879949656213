import styled from 'styled-components';

export const AnchorContainer = styled.span`
  position: relative;
  user-select: none;
`;

export const Anchor = styled.a`
  position: absolute;
  top: -60px;
`;
