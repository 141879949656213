export const formatDate = (date: string | null) => {
    if (!date) {
        return '';
    }

    const dateInstance = new Date(date);

    const month = (dateInstance.getMonth() + 1).toString().padStart(2, '0');
    const day = dateInstance
        .getDate()
        .toString()
        .padStart(2, '0');
    const year = dateInstance.getFullYear();

    return `${day}.${month}.${year}`;
};

export function isDateGreaterThan(date1: string, date2: string): boolean {
    function parseDate(date: string): Date {
        const [day, month, year] = date.split('.').map(Number);
        return new Date(year, month - 1, day);
    }

    // Parse both dates
    const parsedDate1 = parseDate(date1);
    const parsedDate2 = parseDate(date2);

    return parsedDate1 > parsedDate2;
}
