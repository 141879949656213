import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import {muiSpacing} from "@gannochenko/ui.styled-components";

export const Root = styled.div`
    margin-bottom: 2rem;
`;

export const GridCell = styled(Grid)`
    margin-bottom: ${muiSpacing(5)};
`;
