import React, { FunctionComponent } from 'react';

import { BlogCardPropsType } from './type';
import { HOBBIES_DETAIL, fillTemplate } from '../../../pathTemplates';
import {Card} from "../../Card";

export const HobbyCard: FunctionComponent<BlogCardPropsType> = ({ data }) => {
    const slug = data.slug ?? "";
    const title = data.title ?? "";

    const path = fillTemplate(HOBBIES_DETAIL, { slug });

    return (<Card image={data.images[0].image ?? null} title={title} link={path} date={data.date} showOnlyYear text={data.description ?? ""} />);
};
