import {BlogListPropsType, NodeType} from '../type';
import {useState} from "react";
import {useFuse} from "../../../hooks/useFuse";
import {BlogRecordType} from "../../../type";
import { BLOG_DETAIL, fillTemplate } from '../../../pathTemplates';

export const useBlogList = (
    { data, showLink, showOnlyYear, drafts, showSearchBar, itemLinkTemplate, allItemsLinkTemplate, showAllLinkText, cardImageYPosition, ...restProps }: BlogListPropsType,
) => {
    const [query, setQuery] = useState("");
    const { result } = useFuse<NodeType>(query, ['node.frontmatter.title', 'node.frontmatter.description'], data ?? []);

    return {
        rootProps: restProps,
        data: result,
        searchBarProps: {
            query,
            onQueryChange: setQuery,
        },
        showLink,
        drafts,
        nothingFound: result.length === 0,
        somethingFound: result.length !== 0,
        showSearchBar,
        getSeeAllProps: () => {
            return {
                to: allItemsLinkTemplate,
            };
        },
        getItemProps: (item: BlogRecordType) => {
            const {
                frontmatter: { slug, description, date, title, images },
            } = item;

            return {
                title,
                link: fillTemplate(itemLinkTemplate ?? BLOG_DETAIL, {slug}),
                image: images?.[0].image ?? null,
                date,
                text: description,
                imageYPosition: cardImageYPosition,
                showOnlyYear,
            };
        },
        seeAllButtonText: showAllLinkText ?? "See all posts",
    };
};
